import React, { useState, useEffect } from 'react'
import { Row, Col, Button, Input, Spin, Table, notification, Checkbox, Modal, Form } from 'antd'
import { navigate } from 'gatsby'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { faChevronLeft, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'
import MainLayout from '../../../components/mainlayout'
import client from '../../../api/client'
import StatusTag from '../../../components/base/status'
// import 'bootstrap/scss/bootstrap.scss'
import OrderSuccessImg from '../../../images/ordersuccess.svg'
import { ORDER_TYPES } from '../../../constants/enum'
const formatPhone = (number) => {
  if (number) {
    const cleaned = number.replace(/\D/g, '').substring(0, 10)

    const zip = cleaned.substring(0, 3)
    const middle = cleaned.substring(3, 6)
    const last = cleaned.substring(6, 10)

    let ret = ''
    if (cleaned.length > 6) ret = `(${zip}) ${middle}-${last}`
    else if (cleaned.length > 3) ret = `(${zip}) ${middle}`
    else if (cleaned.length > 0) ret = `(${zip}`
    return ret
  }
  return ''
}
export default function OrderDetails(props) {
  const { confirm } = Modal
  const { TextArea } = Input
  const [addTrackingForm] = Form.useForm()
  const [editTrackingForm] = Form.useForm()
  const [receiveMessageForm] = Form.useForm()
  const [receiveMessageLoading, setReceiveMessageLoading] = useState(false)
  const [loading, setLoading] = useState(false)
  const [orderId, setOrderId] = useState(null)
  const [addressLink, setAddressLink] = useState('')
  const [orderDetails, setOrderDetails] = useState(null)
  const [orderItemTypes, setOrderItemTypes] = useState('')
  const [trackingList, setTrackingList] = useState([])
  const [addTrackingModalVisible, setAddTrackingModalVisible] = useState(false)
  const [splitOrderModalVisible, setSplitOrderModalVisible] = useState(false)
  const [splitTrackList, setSplitTrackList] = useState([])
  const [newSplitOrderId, setNewSplitOrderId] = useState(0)
  const [editTrackingModalVisible, setEditTrackingModalVisible] = useState(false)
  const [orderSuccessModalVisible, setOrderSuccessModalVisible] = useState(false)
  const [orderDetailPaymentType, setOrderDetailPaymentType] = useState(null)
  const [customerComment, setCustomerComment] = useState('')

  useEffect(() => {
    setOrderId(props.id)
  }, [props.id])
  useEffect(() => {
    if (orderId) {
      setLoading(true)
      client
        .get(`/api/orders/${orderId}`)
        .then(async (res) => {
          setOrderDetails(res.data)
          receiveMessageForm.setFieldsValue({ willReceiveMessage: res.data.willReceiveMessage })
          switch (res.data.paymentType) {
            case 'Cash':
              setOrderDetailPaymentType('Бэлэн')
              break
            case 'Transfer':
              setOrderDetailPaymentType('Шилжүүлэг')
              break
            case 'Card':
              setOrderDetailPaymentType('Карт')
              break
            case 'InMongolia':
              setOrderDetailPaymentType('Монголд')
              break
            default:
              setOrderDetailPaymentType(null)
          }
          setCustomerComment(res.data.customerComment)
          setAddressLink('https://maps.google.com/?q=' + res.data.branch.address)
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }, [orderId, receiveMessageForm])
  useEffect(() => {
    if (orderId) {
      client.get(`/api/orders/${orderId}/tracks`).then(async (res) => {
        setTrackingList(res.data)
      })
    }
  }, [orderId])
  useEffect(() => {
    if (orderId) {
      const endpoint = `/api/orders/${orderId}/orderitems`
      client.get(endpoint).then(async (res) => {
        const orderType = res.data.map((el) => {
          return el.orderItemTypeName
        })
        setOrderItemTypes(orderType.join(', '))
      })
    }
  }, [orderId])
  const deleteOrder = () => {
    if (orderId) {
      const endpoint = `/api/orders/${orderId}`
      client.delete(endpoint).then(async (res) => {
        notification.success({ message: 'Амжилттай устлаа' })
        navigate('/')
      })
    }
  }
  const addTrackingNumber = async () => {
    if (orderId) {
      await addTrackingForm.validateFields().then(async (values) => {
        addTrackingForm.resetFields()
        await client.post(`/api/orders/${orderId}/tracks`, values).then(async (res) => {
          notification.success({ message: 'Трак дугаар амжилттай нэмэгдлээ' })
          setAddTrackingModalVisible(false)
          client.get(`/api/orders/${orderId}/tracks/`).then(async (res) => {
            setTrackingList(res.data)
          })
        })
      })
    }
  }
  const editTrackingNumber = async () => {
    if (orderId) {
      await editTrackingForm.validateFields().then(async (values) => {
        await client.put(`/api/orders/tracks/${values.trackId}`, values).then(async (res) => {
          editTrackingForm.resetFields()
          notification.success({
            message: 'Трак дугаар амжилттай засагдлаа',
          })
          setEditTrackingModalVisible(false)
          client.get(`/api/orders/${orderId}/tracks/`).then(async (res) => {
            setTrackingList(res.data)
          })
        })
      })
    }
  }
  const receiveMessage = async (value) => {
    setReceiveMessageLoading(true)
    await client
      .put(`/api/orders/${orderId}/messagestatus`, { willReceiveMessage: value })
      .then((res) => {
        if (res.status === 200) {
          notification.success({
            message: res.data.willReceiveMessage ? 'Мэссэж болон и-мейл хүлээн авдаг боллоо!' : 'Мэссэж болон и-мейл хүлээн авдаггүй боллоо!',
          })
        }
      })
      .finally(() => setReceiveMessageLoading(false))
  }
  const getTrackingNumber = async (trackId) => {
    if (orderId) {
      await editTrackingForm.validateFields().then(async (values) => {
        editTrackingForm.resetFields()
        await client.get(`/api/orders/tracks/${trackId}`).then(async (res) => {
          setEditTrackingModalVisible(true)
          editTrackingForm.setFieldsValue({
            trackId: res.data.id,
            number: res.data.number,
            comment: res.data.comment,
          })
        })
      })
    }
  }
  const deleteTrackingNumber = async (trackId) => {
    if (orderId) {
      await client.delete(`/api/orders/tracks/${trackId}`).then(async (res) => {
        client.get(`/api/orders/${orderId}/tracks/`).then(async (response) => {
          setTrackingList(response.data)
          notification.success({ message: 'Трак дугаар амжилттай устгагдлаа' })
        })
      })
    }
  }
  const splitOrderSubmit = async () => {
    if (orderId) {
      await client.post(`/api/orders/${orderId}/split`, { trackIds: splitTrackList }).then(async (res) => {
        setNewSplitOrderId(res.data.id)
        setOrderSuccessModalVisible(true)
      })
    }
  }
  const columns = [
    {
      title: 'Трак дугаар',
      className: 'text-center',
      dataIndex: 'number',
    },
    {
      title: 'Бүртгэсэн огноо',
      className: 'text-center',
      dataIndex: 'createdDate',
      responsive: ['md'],
      render: function (record) {
        return record ? moment(record).format('MM/DD/YYYY') : ''
      },
    },
    {
      title: 'Хүлээн авсан огноо',
      className: 'text-center',
      dataIndex: 'shippedDate',
      responsive: ['md'],
      render: function (record) {
        return record ? moment(record).format('MM/DD/YYYY') : ''
      },
    },
    {
      title: 'Тэмдэглэл',
      className: 'text-center',
      dataIndex: 'comment',
      width: '11%',
      responsive: ['md'],
    },
    {
      title: 'Статус',
      className: 'text-center',
      dataIndex: 'trackStatus',
      responsive: ['sm'],
      render: function (record) {
        return <StatusTag status={record} />
      },
    },
    {
      title: '',
      className: 'text-center',
      render: function (record) {
        if (record.trackStatus.id === 0 && orderDetails.customerStatus.id < 2) {
          return (
            <>
              <div>
                <Button type="link" className="tracking-list-action-btn" onClick={() => getTrackingNumber(record.id)}>
                  Засварлах
                </Button>
                /
                <Button
                  type="link"
                  className="tracking-list-action-btn"
                  style={{ color: '#F8031B' }}
                  onClick={() => {
                    confirm({
                      title: 'Энэ трак дугаарыг устгахдаа итгэлтэй байна уу?',
                      icon: <ExclamationCircleOutlined />,
                      cancelText: 'Үгүй',
                      okText: 'Тийм',
                      okType: 'danger',
                      className: 'confirm-hs-modal',
                      centered: true,
                      confirmLoading: true,
                      onOk() {
                        deleteTrackingNumber(record.id)
                      },
                    })
                  }}
                >
                  Устгах
                </Button>
              </div>
            </>
          )
        } else return <></>
      },
    },
  ]
  if (orderId && orderDetails) {
    return (
      <Spin spinning={loading}>
        <MainLayout>
          <Row>
            <Col className="text-center w-100 mb-4">
              <div className="sb-arrow-back">
                <Button
                  type="link"
                  className="sb-arrow-back"
                  onClick={() => {
                    navigate('/')
                  }}
                >
                  <FontAwesomeIcon icon={faChevronLeft} size="xs" />
                  <span>Буцах</span>
                </Button>
              </div>
              <span className="text-uppercase order-details-title">Илгээмжийн дэлгэрэнгүй мэдээлэл</span>
            </Col>
          </Row>
          <Row gutter={[20, 0]}>
            <Col span={24}>
              <Row gutter={[20, 0]}>
                <Col span={24}>
                  <Row>
                    <Col flex="auto">
                      <div className="order-details-card">
                        <div>
                          <span className="me-3 order-details-card-code">{orderDetails.code}</span>
                        </div>
                        <div>
                          <div style={{ marginRight: 30 }}>
                            <StatusTag customStyles={customTagStyle} status={orderDetails.customerStatus} />
                          </div>
                        </div>
                        <div className="order-details-card-brief">
                          {orderDetails?.shipmentType === 'Air' && (
                            <div style={orderSubDetailStyle}>
                              <div style={{ fontWeight: '700' }}>{orderDetails.weight}кг</div>
                              <div>Жин</div>
                            </div>
                          )}
                          <div style={orderSubDetailStyle}>
                            <div style={{ fontWeight: '700' }}>${orderDetails.amount}</div>
                            <div>Үнэ</div>
                          </div>
                        </div>
                        <div className="order-details-card-payment" style={orderSubDetailStyle}>
                          <div style={{ fontWeight: '700' }}>{orderDetailPaymentType}</div>
                          <div>Төлбөрийн төрөл</div>
                        </div>
                      </div>
                    </Col>
                    <Col
                      flex="170px"
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'column',
                      }}
                    >
                      {orderDetails.bulkOrderId ? (
                        <Button
                          type="link"
                          className="btn-sb btn-sb-primary px-3 w-100 mb-2"
                          onClick={() => {
                            navigate(`/bulk/${orderDetails.bulkOrderId}`)
                          }}
                        >
                          Багц илгээмж
                        </Button>
                      ) : (
                        ''
                      )}
                      {orderDetails.customerStatus.id < 2 ? (
                        trackingList.length > 1 ? (
                          <Button
                            type="link"
                            className="btn-sb btn-sb-primary px-3 w-100 mb-2"
                            onClick={() => {
                              setSplitOrderModalVisible(true)
                            }}
                          >
                            Илгээмж салгах
                          </Button>
                        ) : (
                          <Button type="link" className="btn-sb btn-sb-primary px-3 w-100 mb-2" disabled>
                            Илгээмж салгах
                          </Button>
                        )
                      ) : (
                        ''
                      )}
                      {orderDetails.customerStatus.id === 0 ? (
                        <Button
                          type="link"
                          className="btn-sb btn-sb-primary px-3 w-100"
                          onClick={() => {
                            navigate(`/order/edit/${orderDetails.id}`)
                          }}
                        >
                          Засварлах
                        </Button>
                      ) : (
                        ''
                      )}
                    </Col>
                  </Row>
                </Col>
                <Col span={24}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ color: '#333', fontSize: '30px' }}>Баталгаажуулах код: {orderDetails.verificationCode}</div>
                    <div
                      style={{
                        marginLeft: 24,
                        border: '1px solid #b4b4b47a',
                        borderRadius: 4,
                        boxShadow: '0px 0px 4px #b4b4b47a',
                        padding: '2px 5px',
                      }}
                    >
                      <Spin spinning={receiveMessageLoading}>
                        <Form form={receiveMessageForm} name="receiveMessageForm">
                          <Form.Item valuePropName="checked" name="willReceiveMessage" style={{ margin: 0 }}>
                            <Checkbox className="sb-checkbox" onChange={(e) => receiveMessage(e.target.checked)}>
                              <div style={{ color: '#333', fontSize: '16px' }}>Мэссэж болон и-мейл хүлээн авах</div>
                            </Checkbox>
                          </Form.Item>
                        </Form>
                      </Spin>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row gutter={[20, 20]} className="mb-3">
            <Col xs={24} md={12} lg={9}>
              <Row gutter={[20, 20]}>
                <Col span={24}>
                  <Row className="sb-border-small sb-shadow-2 p-3 bg-grey-medium sb-text-tiny sb-font-normal">
                    <Col span={24} className="mt-1">
                      <span className="sb-text-tiny sb-font-family-bold sb-font-bold">Илгээгч</span>
                    </Col>
                    <Col span={12} className="mt-1 pe-2">
                      <span>{formatPhone(orderDetails?.sender?.phoneNumber)}</span>
                    </Col>
                    <Col span={12} className="mt-1">
                      <span>
                        {orderDetails?.sender?.firstName} {orderDetails?.sender?.lastName}
                      </span>
                    </Col>
                    <Col span={24} className="mt-1">
                      <span>{orderDetails.sender.address}</span>
                    </Col>
                  </Row>
                </Col>
                <Col span={24}>
                  <Row className="sb-border-small sb-shadow-2 p-3 bg-grey-medium sb-text-tiny sb-font-normal">
                    <Col span={24} className="mt-1">
                      <span className="sb-text-tiny sb-font-family-bold sb-font-bold">Хүлээн авагч</span>
                    </Col>
                    <Col span={12} className="mt-1">
                      <span>{orderDetails.receiver.phoneNumber}</span>
                    </Col>
                    <Col span={12} className="mt-1">
                      <span>
                        {orderDetails.receiver.firstName} {orderDetails.receiver.lastName}
                      </span>
                    </Col>
                    <Col span={24} className="mt-1">
                      <span>{orderDetails.receiver.fullAddress}</span>
                    </Col>
                  </Row>
                </Col>
                <Col span={24}>
                  <Row className="sb-border-small sb-shadow-2 p-3 bg-grey-medium sb-text-tiny sb-font-normal">
                    <Col span={12} className="mt-1">
                      <span>Бүртгэгдсэн огноо</span>
                    </Col>
                    <Col span={12} className="mt-1">
                      <span>{moment(orderDetails.createdDate).format('MM/DD/YYYY')}</span>
                    </Col>
                    <Col span={12} className="mt-1">
                      <span>Үнийн дүн</span>
                    </Col>
                    <Col span={12} className="mt-1">
                      <span>${orderDetails.price}</span>
                    </Col>
                    <Col span={12} className="mt-1">
                      <span>Явуулж буй илгээмж</span>
                    </Col>
                    <Col span={12} className="mt-1">
                      <span>{orderItemTypes}</span>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col xs={24} md={12} lg={15}>
              <Row gutter={[20, 20]}>
                <Col span={24}>
                  <Row className="sb-border-small sb-shadow-2 p-3 bg-grey-medium sb-text-tiny sb-font-normal">
                    <Col span={24} className="mt-1">
                      <span className="sb-text-tiny sb-font-family-bold sb-font-bold">Илгээмжийн мэдээлэл</span>
                    </Col>
                    <Col span={12} className="mt-1 pe-2">
                      <span>Илгээмжийн төрөл</span>
                    </Col>
                    <Col span={12} className="mt-1">
                      <span>{ORDER_TYPES[orderDetails.shipmentType]}</span>
                    </Col>
                    <Col span={12} className="mt-1 pe-2">
                      <span>Хүлээн авах салбар</span>
                    </Col>
                    <Col span={12} className="mt-1">
                      <span>
                        <FontAwesomeIcon icon={faMapMarkerAlt} size="1x" style={{ color: '#11427F', marginRight: '5px' }} />
                        <a href={addressLink} target="_blank" rel="noreferrer" style={{ color: '#11427F' }}>
                          {orderDetails.branch.name}
                        </a>
                      </span>
                    </Col>
                  </Row>
                </Col>
                <Col span={24}>
                  <Row className="sb-text-tiny ">
                    <Col span={24} className="mt-1">
                      <span>Хэрэглэгчийн тайлбар</span>
                    </Col>
                    <Col span={24} className="mt-1">
                      <TextArea
                        rows={3}
                        className="sb-textarea"
                        value={customerComment}
                        style={{ fontSize: 17 }}
                        disabled
                        // onChange={(e)=>{
                        //   setCustomerComment(e.target.value)
                        // }}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col span={24}>
                  <Row className="sb-text-tiny ">
                    <Col span={24} className="mt-1">
                      <span>Админы тайлбар</span>
                    </Col>
                    <Col span={24} className="mt-1">
                      <TextArea rows={3} className="sb-textarea" disabled value={orderDetails.adminComment} style={{ fontSize: 17 }} />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col span={24} className="mt-3">
              <h4>Трак дугаар ({trackingList.length})</h4>
            </Col>
            <Col span={24}>
              <Table
                className="sb-table mt-3"
                columns={columns}
                dataSource={trackingList}
                rowKey={(record) => record.id}
                locale={{ emptyText: 'Мэдээлэл байхгүй байна' }}
                loading={false}
                pagination={false}
              />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col xs={8} sm={8} md={8} lg={8} xl={6}>
              {orderDetails.customerStatus.id === 0 ? (
                <Button
                  className="ant-btn btn-sb px-3"
                  style={{ color: '#F30404', fontWeight: '500' }}
                  onClick={() => {
                    confirm({
                      title: 'Энэ илгээмжийг цуцлахдаа итгэлтэй байна уу?',
                      icon: <ExclamationCircleOutlined />,
                      centered: true,
                      onOk() {
                        deleteOrder()
                      },
                      onCancel() {},
                    })
                  }}
                >
                  Цуцлах
                </Button>
              ) : (
                ''
              )}
            </Col>
            <Col xs={8} sm={8} md={8} lg={8} xl={12}></Col>
            <Col xs={8} sm={8} md={8} lg={8} xl={6}>
              {orderDetails.customerStatus.id < 2 ? (
                <Button className="ant-btn w-100 btn-sb btn-sb-primary" onClick={() => setAddTrackingModalVisible(true)}>
                  Трак бүртгэх
                </Button>
              ) : (
                ''
              )}
            </Col>
          </Row>
          <Modal
            title="Шинэ трак дугаар"
            open={addTrackingModalVisible}
            onCancel={() => {
              setAddTrackingModalVisible(false)
            }}
            className="sb-modal sb-modal-kiosk"
            width={400}
            destroyOnClose
            centered
            footer={[
              <Row justify="center" key={'modal'}>
                <Col span={24}>
                  <Button className="btn-sb btn-sb-primary w-100" onClick={() => addTrackingNumber()}>
                    Хадгалах
                  </Button>
                </Col>
              </Row>,
            ]}
          >
            <Form form={addTrackingForm}>
              <Row gutter={[16, 0]}>
                <Col span={24}>
                  <Form.Item
                    name="number"
                    rules={[
                      {
                        required: true,
                        message: 'Трак дугаараа оруулна уу!',
                      },
                    ]}
                  >
                    <Input placeholder="Трак дугаар" className="sb-input sb-shadow" style={{ height: 60 }} />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item name="comment" className="mb-0">
                    <Input.TextArea
                      rows={4}
                      className="sb-shadow sb-input-area"
                      placeholder="Тэмдэглэл"
                      style={{ fontSize: '20px', padding: '11px' }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Modal>
          <Modal
            title="Трак дугаар засах"
            open={editTrackingModalVisible}
            onCancel={() => {
              setEditTrackingModalVisible(false)
            }}
            className="sb-modal sb-modal-kiosk"
            width={400}
            destroyOnClose
            centered
            footer={[
              <Row justify="center" key={'modal'}>
                <Col span={24}>
                  <Button className="btn-sb btn-sb-primary w-100" onClick={() => editTrackingNumber()}>
                    Хадгалах
                  </Button>
                </Col>
              </Row>,
            ]}
          >
            <Form form={editTrackingForm}>
              <Row gutter={[16, 0]}>
                <Col span={24}>
                  <Form.Item name="trackId" hidden={true}>
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name="number"
                    rules={[
                      {
                        required: true,
                        message: 'Трак дугаараа оруулна уу!',
                      },
                    ]}
                  >
                    <Input placeholder="Трак дугаар" className="sb-input sb-shadow" style={{ height: 60 }} />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item name="comment" className="mb-0">
                    <Input.TextArea
                      rows={4}
                      className="sb-shadow sb-input-area"
                      placeholder="Тэмдэглэл"
                      style={{ fontSize: '20px', padding: '11px' }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Modal>
          <Modal
            title="Илгээмж салгах"
            open={splitOrderModalVisible}
            onCancel={() => {
              setSplitOrderModalVisible(false)
            }}
            className="sb-modal sb-modal-kiosk"
            width={600}
            destroyOnClose
            centered
            footer={[
              <Row justify="center" key={'modal'} gutter={[24, 24]}>
                <Col span={12} offset={12}>
                  <Button className="btn-sb btn-sb-primary w-100" onClick={() => splitOrderSubmit()}>
                    Салгах
                  </Button>
                </Col>
              </Row>,
            ]}
          >
            <Row gutter={[16, 0]} style={{ fontSize: '16px', fontWeight: 400 }}>
              <Col span={24} className="mb-3">
                Салгах илгээмжийн трак дугаарыг сонгоно уу
              </Col>
              <Col offset={2}>Трак дугаар</Col>
              <Col offset={7}>Тэмдэглэл</Col>
              <Col span={24}>
                <Checkbox.Group
                  style={{ width: '100%' }}
                  onChange={(e) => {
                    setSplitTrackList(e)
                  }}
                >
                  <Row>
                    {trackingList.map((trackEl) => {
                      return (
                        <Col span={24} key={trackEl.id} className="my-2">
                          <Checkbox value={trackEl.id} className="orderSplitItem">
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                fontSize: 16,
                              }}
                            >
                              <div style={{ width: 260 }}>{trackEl.number}</div>
                              <div>{trackEl.comment}</div>
                            </div>
                          </Checkbox>
                        </Col>
                      )
                    })}
                  </Row>
                </Checkbox.Group>
              </Col>
            </Row>
          </Modal>
          <Modal
            open={orderSuccessModalVisible}
            onCancel={() => {
              navigate('/')
            }}
            className="sb-modal sb-modal-kiosk"
            width={400}
            destroyOnClose
            centered
            maskStyle={{
              background: '#11427FB9',
            }}
            footer={[
              <Row justify="center" key={'modal'} gutter={[12, 24]}>
                <Col span={12}>
                  <Button className="btn-sb btn-sb-primary w-100" target="_blank" href={`/order/${newSplitOrderId}`}>
                    Шинэ илгээмж
                  </Button>
                </Col>
                <Col span={12}>
                  <Button
                    className="btn-sb btn-sb-primary w-100"
                    onClick={() => {
                      client.get(`/api/orders/${orderId}/tracks/`).then(async (res) => {
                        setTrackingList(res.data)
                      })
                      setSplitOrderModalVisible(false)
                      setOrderSuccessModalVisible(false)
                    }}
                  >
                    Буцах
                  </Button>
                </Col>
              </Row>,
            ]}
          >
            <div
              style={{
                height: '200px',
                paddingTop: '130px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                overflow: 'auto',
              }}
            >
              <div style={{ position: 'absolute', top: '-68px', left: '143px' }}>
                <img src={OrderSuccessImg} alt="img" style={{ width: '180px' }} />
              </div>
              <div>
                <div style={{ fontSize: 22, textAlign: 'center' }}>Таны ачаа амжилттай салгагдлаа.</div>
              </div>
            </div>
          </Modal>
        </MainLayout>
      </Spin>
    )
  } else {
    return (
      <MainLayout>
        <Row>
          <Col className="text-center w-100 mb-4">
            <Button
              type="link"
              className="sb-arrow-back"
              onClick={() => {
                navigate('/')
              }}
            >
              <FontAwesomeIcon icon={faChevronLeft} size="xs" />
              <span>Буцах</span>
            </Button>
          </Col>
        </Row>
        Илгээмж олдсонгүй
      </MainLayout>
    )
  }
}

const customTagStyle = {
  fontSize: '22px',
  minWidth: '150px',
  lineHeight: '33px',
}

const orderSubDetailStyle = {
  fontSize: '23px',
  textAlign: 'center',
  lineHeight: '28px',
  marginLeft: '2rem',
  marginRight: '2rem',
  whiteSpace: 'nowrap',
}

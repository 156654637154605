export const ADMIN_STATUS = {}
export const CUSTOMER_STATUS = {}
export const ORDER_TYPES = {
  Express: 'Express ачаа',
  Air: 'Агаарын ачаа',
  Ground: 'Газрын ачаа',
  Container: 'Чингэлэг ачаа',
}
export const SHIPMENT_TYPES = {
  Express: 'Express',
  Air: 'Агаар',
  Ground: 'Газар',
  Container: 'Чингэлэг',
}
export const SHIPMENT_TYPES_TOENG = {
  Express: 'Express',
  Агаар: 'Air',
  Газар: 'Ground',
  Чингэлэг: 'Container',
}

import React from "react";

function StatusTag(props) {
  const styleMain = {
    color: "#FFFFFF",
    borderRadius: "16px",
    backgroundColor: "#" + props?.status?.color ?? "#EFB62A",
    textAlign: "center",
    width: "auto",
    display: "inline-block",
    margin: "0 5px",
    paddingTop: "5px",
    paddingBottom: "5px",
    paddingRight: "10px",
    paddingLeft: "10px",
    lineHeight: props?.customStyles?.lineHeight ?? "1.2",
    fontSize: props?.customStyles?.fontSize ?? "1rem",
    minWidth: props?.customStyles?.minWidth ?? "120px",
    boxShadow: `0px 3px 10px #${props?.status?.color ?? "#EFB62A"}`,
  };
  return <div style={styleMain}>{props?.status?.name}</div>;
}
export default StatusTag;

import React, { useEffect, useState } from 'react'
import { Dropdown, Layout, Button, Modal, Spin } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { Helmet } from 'react-helmet'
import { navigate } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { isLoggedIn, isMember } from '../api/auth'
import '../styles/global-version.scss'
const { Header, Content, Footer } = Layout
const { confirm } = Modal
const greetingStyle = {
  display: 'inline-flex',
  alignItems: 'center',
  paddingBottom: 2,
}
const headerDropdownStyle = {
  display: 'inline-flex',
  alignItems: 'center',
  paddingBottom: 2,
  flexGrow: 1,
  justifyContent: 'flex-end',
}
const year = new Date().getFullYear()

export default function MainLayout(props) {
  const [firstName, setFirstName] = useState(undefined)
  const [lastName, setLastName] = useState(undefined)
  useEffect(() => {
    setFirstName(localStorage.getItem('firstName'))
    setLastName(localStorage.getItem('lastName'))
  }, [])
  if (!isLoggedIn() || !isMember()) {
    if (typeof window !== 'undefined') window.location.href = '/login'
    // navigate(`/login`);
  }
  return isLoggedIn() && isMember() ? (
    <Layout style={{ background: 'white' }} className="main-layout">
      <Helmet title="SB Express" defer={false} />
      <Header className="sb-shadow bg-base">
        <div className="sb-container d-flex">
          <div style={greetingStyle} className="header-greeting">
            S.B. Express
          </div>
          <div style={headerDropdownStyle}>
            <Dropdown
              placement="bottomRight"
              className="layout-header-dropdown"
              overlayClassName="layout-header-dropdown-overlay"
              trigger={['click']}
              menu={{
                items: [
                  {
                    label: (
                      <Button
                        type="link"
                        onClick={() => {
                          confirm({
                            title: 'Та гарахдаа итгэлтэй байна уу?',
                            maskClosable: true,
                            icon: <ExclamationCircleOutlined />,
                            cancelText: 'Үгүй',
                            okText: 'Тийм',
                            okType: 'danger',
                            className: 'confirm-hs-modal',
                            centered: true,
                            confirmLoading: true,
                            onOk() {
                              localStorage.clear()
                              navigate('/login')
                            },
                          })
                        }}
                      >
                        Гарах
                      </Button>
                    ),
                  },
                ],
              }}
            >
              <div>
                <div className="me-4">
                  {firstName ?? ''} {lastName ?? ''}
                </div>
                <FontAwesomeIcon icon={faChevronDown} size="xs" />
              </div>
            </Dropdown>
          </div>
        </div>
      </Header>
      <Content>
        <div
          style={{
            maxWidth: '1366px',
            margin: 'auto',
            padding: '20px 30px',
          }}
        >
          {props.children}
        </div>
      </Content>
      <Footer className="sb-footer text-center bg-white">S.B. Express, INC {year}</Footer>
    </Layout>
  ) : (
    <div
      style={{
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Spin />
    </div>
  )
}
